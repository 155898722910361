import React from 'react'
import Navigation from '../../components/Navigation'
const Home = () => {
  return (
    <div
      className='px-8 max-[600px]:px-4'
    >
        <h1 className='text-2xl'>Work in progress</h1>
    </div>
  )
}

export default Home